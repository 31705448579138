import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				SERENITY COVE
			</title>
			<meta name={"description"} content={"Тут спокій поєднується з розкішшю в захоплюючому середовищі, створеному для омолодження вашого тіла, розуму та духу. "} />
			<meta property={"og:title"} content={"SERENITY COVE"} />
			<meta property={"og:description"} content={"Тут спокій поєднується з розкішшю в захоплюючому середовищі, створеному для омолодження вашого тіла, розуму та духу. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665453ef1d712a00233434c5/images/2-2.jpg?v=2024-05-27T11:24:00.560Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665453ef1d712a00233434c5/images/2-2.jpg?v=2024-05-27T11:24:00.560Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665453ef1d712a00233434c5/images/2-2.jpg?v=2024-05-27T11:24:00.560Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665453ef1d712a00233434c5/images/2-2.jpg?v=2024-05-27T11:24:00.560Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665453ef1d712a00233434c5/images/2-2.jpg?v=2024-05-27T11:24:00.560Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665453ef1d712a00233434c5/images/2-2.jpg?v=2024-05-27T11:24:00.560Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665453ef1d712a00233434c5/images/2-2.jpg?v=2024-05-27T11:24:00.560Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="link" />
			<Override slot="box" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text1" />
		</Components.Header23>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1554118811-1e0d58224f24?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 46px/1.2 --fontFamily-sans" color="--light">
				ПОСЛУГИ
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="--base"
				color="rgba(255, 255, 255, 0.58)"
				width="700px"
				align-self="center"
				md-align-self="auto"
				max-width="100%"
			>
				Пориньте в різноманітні пропозиції Serenity Cove, де кожна послуга є обіцянкою оновлення та збагачення. Наше всебічне меню розроблено, щоб задовольнити всі ваші потреби в оздоровленні, забезпечуючи гармонійне поєднання традиційних та інноваційних терапій, які задовольняють як тіло, так і душу.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Комплексні спа-процедури
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Ароматерапевтичний масаж: Використовуються ефірні олії для покращення психологічного та фізичного самопочуття.
Шведський масаж: сприяє розслабленню та знімає м’язову напругу за допомогою ніжних погладжувань.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Розширений досвід користування сауною
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Інфрачервона сауна: пропонує глибоке розслаблення тканин і детоксикацію за допомогою інфрачервоного світла.
Парова сауна: Допомагає очистити шкіру та дихальну систему, покращуючи загальний стан здоров’я.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Індивідуальний масаж
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Глибокий масаж тканин: націлений на глибокі шари м’язів для зняття сильної напруги.
Терапія гарячим камінням:  використовує нагріте каміння для розслаблення м’язів і зняття стресу.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Уважність і медитація
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Сеанси медитації під керівництвом експертів, які допоможуть вам знайти душевну ясність і спокій.
Майстер-класи з йоги: Спеціалізовані заняття, які підходять для всіх рівнів, від початківців до досвідчених практиків.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Програми детоксикації та харчування
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Персоналізовані плани детоксикації: Налаштовані відповідно до ваших цілей щодо здоров’я та дієти.
Консультування з питань харчування: пропонує експертні поради щодо оптимізації ваших харчових звичок для здоров’я.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Відкрийте для себе весь потенціал наших послуг, зв’язавшись з нами безпосередньо або відвідавши Serenity Cove. Кожен досвід — це крок до особистого омолодження та оздоровлення, створений відповідно до ваших потреб. Незалежно від того, чи шукаєте ви короткого відпочинку чи глибокого трансформаційного досвіду, ми тут, щоб скерувати вас у вашій подорожі.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-dark" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				FAQ
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--light" sm-margin="0px 0px 50px 0px">
				У розділі поширених запитань ми прагнемо відповісти на деякі з найпоширеніших запитів, щоб допомогти вам безперешкодно спланувати свій візит. Наша прихильність до вашої оздоровчої подорожі починається з того, щоб ви відчували себе повністю поінформованими та комфортними на кожному кроці.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Що мені взяти з собою на зустріч?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Рекомендуємо взяти з собою зручний одяг і купальник, якщо ви плануєте відвідати сауну або басейн. Усі інші зручності, включаючи халати та капці, надаються.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Як рано я повинен прибути до запланованого лікування?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Раннє прибуття на 15-20 хвилин допомагає спокійно розпочати лікування, даючи вам час відпочити та насолодитися нашими можливостями.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи можу я вибрати свого терапевта?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ви можете попросити конкретного терапевта під час запису на прийом за наявності вільних місць.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи можу я вибрати свого терапевта?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ви можете попросити конкретного терапевта під час запису на прийом за наявності вільних місць.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи пропонуєте ви пакети чи членство?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ми пропонуємо різноманітні пакети та членства, призначені для забезпечення постійного оздоровлення. Деталі надаються за запитом.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Чи доступні ваші заклади для людей з проблемами пересування?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, увесь наш заклад розроблено таким чином, щоб бути доступним, щоб кожен міг насолоджуватися нашими оздоровчими послугами.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer1 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});